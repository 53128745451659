import { Injectable } from '@angular/core';
import {Web3Service} from '../web3/web3.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Web3 from 'web3';
import { Notary } from '../../contracts/Notary';
import Contract from '../../abi/Notary.json';
import {Notary as NotaryData} from '../../data/notary.data';
import { EventLog } from 'web3/types';

@Injectable()
export class NotaryService {
  public account: string = null;
  public web3: Web3;

  public contract: Notary;
  // forkDelta contract

  constructor ( private http: HttpClient, private web3Service: Web3Service) {
  }

  /**
   * Set Notary contract in the client side, Metamask must be enabled in order to return true
   */
  public async setContractPersonal(): Promise<boolean> {
      if (this.web3Service.hasPersonalWeb3()) {
        this.web3 = this.web3Service.personalWeb3;
         // @ts-ignore
        this.contract =  new this.web3.eth.Contract(Contract.abi, NotaryData.address);
        return true;

      } else {
        await this.web3Service.injectMetamaskProvider();
        if ( this.web3Service.hasPersonalWeb3()) {
            this.web3 = this.web3Service.personalWeb3;
           // @ts-ignore
             this.contract =  new this.web3.eth.Contract(Contract.abi, NotaryData.address);
        } else {
            return false;
        }
      }
  }
  /**
   * Get all files added
   * @param fromBlock from block to fetch the values
   */
  public getPastEventsFileAdded( fromBlock: number = 0): Promise<EventLog[]> {
      return this.contract.getPastEvents('FileAdded', {
                      fromBlock: fromBlock,
                      toBlock: 'latest'
                  });
  }
   /**
   * Get all files added by address
   * @param fromBlock from block to fetch the values
   */
    public getPastEventsFileAddedByAddress(address: string, fromBlock: number = 0): Promise<EventLog[]> {
        return this.contract.getPastEvents('FileAdded', {
                        filter: { sender: address},
                        fromBlock: fromBlock,
                        toBlock: 'latest'
                    });
    }


  /**
   * [hashFile returns the hash of a file
   * @param  file file reader result
   * @return     file hash
   */
  public hashFile(file: string ): string {
      return this.web3.utils.sha3(file);

  }

}
