import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { environment } from '../../../../../common/environments/environment';
import { NotaryFile } from '../../../../../common/interfaces';
import { AuthService } from '../auth/auth-service.service';
import { CrudHttpService } from '../crud-http/crud-http.service';

@Injectable()
export class TokenHttpService extends CrudHttpService<NotaryFile> {

  public api: string = environment.microservices.agrilio;

  constructor(db: AngularFirestore,  _http: HttpClient,  _auth: AuthService) {
    super(environment.collections.notary, db, _http, _auth);
    this.path = environment.collections.notary;
  }

}
