import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { environment } from '../../../../../common/environments/environment';
import { Referendum } from '../../../../../common/interfaces';
import { AuthService } from '../auth/auth-service.service';
import { CrudHttpService } from '../crud-http/crud-http.service';

@Injectable()
export class DaoHttpService extends CrudHttpService<Referendum> {

  public api: string = environment.microservices.agrilio;

  constructor(db: AngularFirestore,  _http: HttpClient,  _auth: AuthService) {
    super(environment.collections.dao, db, _http, _auth);
    this.path = environment.collections.dao;
  }
  async claimTokens(data: {address: string, sig: string, contract: string}) {
   const authorizationHeader = await this.getAuthorizationHeader();
   return this._http.post<boolean>
           (`${this.api}/${this.path}/claim-tokens`, data, {headers: authorizationHeader}).toPromise();
 }

}
