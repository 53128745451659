import { Injectable } from '@angular/core';
import {Auction} from '../../contracts/Auction';
import { Web3Service } from '../web3/web3.service';
import Contract from '../../abi/Auction.json';
import Web3 from 'web3';
import { BlockType } from 'web3/eth/types';
import { EventLog } from 'web3/types';

@Injectable()
export class AuctionService {
  public contract: Auction;
  private web3: Web3;

  constructor(private web3Service: Web3Service) {
      // Start with raw web3, can be dynamically changed after
      this.web3 = this.web3Service.getWeb3();
      // TODO: It need to set erc20 address
      // @ts-ignore
      this.contract = new this.web3.eth.Contract(Contract.abi);
  }
  setAddress(address: string): void {
    this.contract.options.address = address;
  }
}
