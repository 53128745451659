import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { auth, firestore } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';

import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { createCredentials } from 'crypto';
import * as firebase from 'firebase/app';
import { tap } from 'rxjs/operators';
/**
 * @see https://angularfirebase.com/lessons/google-user-auth-with-firestore-custom-data/
 * @see https://angularfirebase.com/lessons/role-based-permissions-and-authorization-with-firebase-auth/
 * @see https://angularfirebase.com/lessons/role-based-authorization-with-firestore-nosql-and-angular-5/
 * @see https://github.com/auth0-blog/angular-firebase/blob/master/src/app/auth/auth.service.ts
 * @see https://stackoverflow.com/questions/44536041/get-firebase-token-before-making-each-http-request-in-angular-4
 */
@Injectable()
export class AuthService {
  private _user: firebase.User = null;
  public  authState: Observable<firebase.User>;

  /**
   * Constructor
   * @param _router
   * @param _afAuth
   */
  constructor(
    private _router: Router,
    private _afAuth: AngularFireAuth,
    private _http: HttpClient
  ) {
    /*if (localStorage.getItem('token')) {
      this._afAuth.auth.signInWithCustomToken(localStorage.getItem('token'))
       .then(credential => {
         this._user = credential.user;
      });
    }*/
    this.authState = this._afAuth.authState;
    this._afAuth.authState.subscribe((user) => {
      console.log(user);
      if (user) {
        this._user = user;
      }
    });
  }

  /**
   * Register user
   * @param {string} email
   * @param {string} password
   * @returns {Promise<void>}
   */
  register(email: string, password: string): Promise<auth.UserCredential> {
    return this._afAuth.auth.createUserWithEmailAndPassword(email, password);
  }

  /**
   * Send email for reset password
   * @param email
   * @returns {Promise<void>}
   */
  requestPass(email): Promise<void> {
    return this._afAuth.auth.sendPasswordResetEmail(email);
  }

  /**
   * Confirm password reset
   * @param code
   * @param newPassword
   * @returns {Promise<void>}
   */
  confirmPasswordReset(code, newPassword): Promise<void> { // param: oobCode=<code>
    return this._afAuth.auth.confirmPasswordReset(code, newPassword);
  }

  /**
   * Check reset password code
   * @param {string} code
   * @returns {Promise<string>}
   */
  verifyPasswordResetCode(code: string): Promise<string> {
    return this._afAuth.auth.verifyPasswordResetCode(code);
  }

  /**
   * Local login
   * @param email
   * @param password
   * @returns {Promise<firebase.auth.UserCredential>}
   */
  async signInWithEmail(email, password): Promise<void> {

    await this._afAuth.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

    return this._afAuth.auth.signInWithEmailAndPassword(email, password)
       .then(async credential => {} );
  }

  /**
   * Social Twitter login
   * @returns {Promise<void>}
   */
  signInWithTwitter(): Promise<void> {
    const provider = new auth.TwitterAuthProvider();
    return this.oAuthLogin(provider);
  }

  /**
   * Social Facebook login
   * @returns {Promise<void>}
   */
  signInWithFacebook(): Promise<void> {
    const provider = new auth.FacebookAuthProvider();
    return this.oAuthLogin(provider);
  }

  /**
   * Social Google login
   * @returns {Promise<void>}
   */
  signInWithGoogle(): Promise<void> {
    const provider = new auth.GoogleAuthProvider();
    return this.oAuthLogin(provider);
  }

  /**
   * Logout
   * @returns {Promise<void>}
   */
  logout(): Promise<void> {
    return this._afAuth.auth.signOut(); /*.then(() => {
      this._router.navigate(['/']);
    });*/
  }

  /**
   * Check if user is authenticated
   */
  isLoggedIn(): boolean {
    // return (this._afAuth.authState !== null && this._afAuth.auth.currentUser !== null);
    if (this._user == null) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Returns the current user
   */
  getCurrentUser(): firebase.User {
    return this._user;
  }

  /**
   * Social auth login
   * @param provider
   * @returns {Promise<void>}
   */
  private oAuthLogin(provider) {
    return this._afAuth.auth.signInWithPopup(provider).then((credential) => {

    });
  }
  /**
   * Get Firebase token if logged in
   */
  async getToken(): Promise<string>  {
    return this._afAuth.auth.currentUser.getIdToken();
  }
   /**
   *Get Server Metamask Token
   */
  signMetamaskToken(address: string, sig: string): Observable<string>  {
    return this._http.post<string>('api/user/authenticate-metamask', {address: address, sig: sig})
                .pipe( tap( token => this._afAuth.auth.signInWithCustomToken(token) )) ;
  }


}
