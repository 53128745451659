import { Injectable } from '@angular/core';
import { ARC20AssetTemplate} from '../../contracts/ARC20AssetTemplate';
import { Web3Service } from '../web3/web3.service';
import Contract from '../../abi/ARC20AssetTemplate.json';
import Web3 from 'web3';
import { BlockType } from 'web3/eth/types';
import { EventLog } from 'web3/types';

@Injectable()
export class ARC20AssetTemplateService {
  public contract: ARC20AssetTemplate;
  private web3: Web3;

  constructor(private web3Service: Web3Service) {
      // Start with raw web3, can be dynamically changed after
      this.web3 = this.web3Service.getWeb3();
      // TODO: It need to set erc20 address
      // @ts-ignore
      this.contract = new this.web3.eth.Contract(Contract.abi);
  }
  setAddress(address: string): void {
    this.contract.options.address = address;
  }
  /**@summary Example:  Get all transfer events /
   * @param fromBlock  start block
   * @param toBlock get all events Events transfer as default
   * @param from Address who did the transfers
   */
  getTransferEvents( from: string, fromBlock:  BlockType , toBlock: BlockType = 'latest' ): Promise<EventLog[]> {
    return this.contract.getPastEvents('Transfer',
      {
        filter: { from: from},
        fromBlock: fromBlock,
        toBlock: toBlock,
      });
   }

}
