// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyDzfqmbCvk_pyuMJa9ekcVsdxuZXTf7r4A',
    authDomain: 'agrilio-app.firebaseapp.com',
    databaseURL: 'https://agrilio-app.firebaseio.com',
    projectId: 'agrilio-app',
    storageBucket: 'agrilio-app.appspot.com',
    messagingSenderId: '655648709587'
  },
  collections: {
    producers: 'producers',
    userAccount: 'users',
    events: 'events',
    notifications: 'notifications',
    dao: 'dao',
    auction: 'auction',
    token: 'token',
    snapshot: 'snapshot',
    notary: 'notary',
    reports: 'reports',
  },
  infura: {
    apiKey: '',
    endPoint: 'https://mainnet.infura.io/v3/',
  },
  contracts: {
    agrilioToken: '',
  },
  daoSignMessage: 'Claim Tokens To Vote in Referendum',
  etherscan: {
   baseUrl: 'https://etherscan.io/'
  },
   api_url: 'http://localhost:5000/agrilio-app/us-central1/userservice',

   microservices: {
     agrilio: 'http://localhost:5000/agrilio-app/us-central1/userservice'
   },
   agrilio: {
     address: '0xa487655e393bd341fd138b5b687f672c9534d873',
     privateKey: '0xd298b40a04bbffe698468426dca6f9fb661437de42c260f2e7319cf5eafafe9b'
   }

};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
