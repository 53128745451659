import { AngularFirestore, AngularFirestoreCollection,  QueryFn } from '@angular/fire/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth-service.service';

/**
 * Generic Crud Service
 * Using Nest api to update and create, and using firebase to find documents
 * @see https://angularfirebase.com/lessons/firestore-nosql-data-modeling-by-example/
 */
export abstract class CrudHttpService<T> {

  protected collection: AngularFirestoreCollection<T>;
  protected path: string;
  protected api: string;

  /**
   * Initialize a collection
   * @param {string} path
   * @param {AngularFirestore} db
   */
  constructor(protected collectionPath: string, protected db: AngularFirestore, protected _http: HttpClient, protected _auth: AuthService) {
    this.collection = db.collection<T>(collectionPath);
  }

  /**
   * Return a specific query
   * @param {QueryFn} query
   * @returns {Observable<T[]>}
   */
  find(query: QueryFn): Observable<T[]> {
    return this.db.collection<T>(this.path, query).valueChanges();
  }

  /**
   * Return specific search by uid
   * @param {string} uid
   * @returns {Observable<T | undefined>}
   */
  findOne(uid: string): Observable<T | undefined> {
    return this.collection.doc<T>(uid).valueChanges(); /*take<T>(1);*/
  }

  /**
   * Returns entire search
   * @returns {Observable<T[]>}
   */
  findAll(): Observable<T[]> {
    return this.collection.valueChanges();
  }
   /**
   * Call this function when user is logged in
   */
  async getAuthorizationHeader(): Promise<HttpHeaders> {
      // TODO: fix authorization header
      const token = await this._auth.getToken();
      // const token = 'token';
      return new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      });
  }
  /**
   * Create a new document, with random or defined id
   * @param {T} model
   * @returns {Promise<DocumentReference>}
   */
  async create(model: T) {
    const authorizationHeader = await this.getAuthorizationHeader();
    console.log(authorizationHeader);
    return this._http.post<T>
            (`${this.api}/${this.path}/create`, model, {headers: authorizationHeader}).toPromise();
  }

  /**
   * Update a document
   * @param {T} model
   * @returns {Promise<void>}
   */
  async update(model: T) {
    const authorizationHeader = await this.getAuthorizationHeader();
    return this._http.post<T>
            (`${this.api}/${this.path}/update`, model, {headers: authorizationHeader}).toPromise();
  }

  /**
   * Remove a document
   * @param {T} model
   * @returns {Promise<void>}
   */
  remove(model: T): Promise<void> {
    return this.collection.doc(model['uid']).delete();
  }

}
