import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppMaterialModule } from './app-material.module';



import { SharedModule } from './shared/shared.module';
import { Web3Service } from '../../common/services';
import { Web3GuardService } from './shared/services/web3/web3-guard.service';
import { environment } from '../../common/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AuthService } from './shared/services/auth/auth-service.service';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AuthGuard } from './shared/services/auth/auth-guard.service';
import { FullModule } from './layouts/full/full.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SpinnerModule } from './shared/spinner/spinner.module';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

import { AgmCoreModule } from '@agm/core';


@NgModule({
  declarations: [
     AppComponent,
     FullComponent,
    AppHeaderComponent,
    AppBlankComponent,
    AppSidebarComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AppMaterialModule,
    SpinnerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAI53C3-e3Sa-T0Z9TrRStvNPMx25moaZY'
    }),
   // FullModule,
    // DemoMaterialModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    PerfectScrollbarModule,
    SharedModule,
    RouterModule.forRoot(AppRoutes)
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    Web3Service,
    AuthService,
    AuthGuard,
    Web3GuardService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
