import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface separator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  separator?: separator[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
    {
        state: '',
        name: 'Personal',
        type: 'separator',
        icon: 'av_timer'
    }, {
        state: 'dashboard',
        name: 'Dashboard',
        type: 'link',
        icon: 'dashboard',
    },
    /*{
        state: 'wallet',
        name: 'Wallet',
        type: 'link',
        icon: 'account_balance_wallet'
    },
    {
        state: 'taskboard',
        name: 'Calendar & Tasks',
        type: 'link',
        icon: 'event',
    },*/ {
        state: 'farm',
        name: 'Farms',
        type: 'link',
        icon: 'nature_people'
    }, {
        state: 'patch',
        name: 'Patches',
        type: 'link',
        icon: 'view_module'
    }, {
        state: 'animal',
        name: 'Animals',
        type: 'link',
        icon: 'pets'
    }, {
        state: 'produce',
        name: 'Produce',
        type: 'link',
        icon: 'local_florist'
    }, {
        state: 'asset',
        name: 'Assets',
        type: 'link',
        icon: 'rv_hookup'
    }, {
        state: 'sensor',
        name: 'Sensors',
        type: 'link',
        icon: 'wifi_tethering'
    },  {
        state: 'report',
        name: 'Reports',
        type: 'link',
        icon: 'equalizer'
    }, {
        state: '',
        name: 'P2P',
        type: 'separator',
        icon: 'av_timer'
    }, {
        state: 'exchange',
        name: 'Exchange',
        type: 'link',
        icon: 'timeline'
    }, {
        state: 'market',
        name: 'Marketplace',
        type: 'link',
        icon: 'store'
    }, {
        state: 'auction',
        name: 'Auctions',
        type: 'link',
        icon: 'gavel'
    }, {
        state: 'crowdsale',
        name: 'Crowdsales',
        type: 'link',
        icon: 'gamepad'
    }, {
        state: 'https://nexo.io/',
        name: 'Loans',
        type: 'extTabLink',
        icon: 'local_atm'
    }, {
        state: '',
        name: 'Toolbox',
        type: 'separator',
        icon: 'av_timer'
    }, {
        state: 'newsalerts',
        name: 'News & Alerts',
        type: 'link',
        icon: 'new_releases'
    }, {
        state: 'explorer',
        name: 'Explorer',
        type: 'link',
        icon: 'search'
    }, {
        state: 'notary',
        name: 'Notary',
        type: 'link',
        icon: 'library_books'
    }, {
        state: 'tracking',
        name: 'Tracking',
        type: 'link',
        icon: 'fingerprint'
    }, {
        state: 'encyclopedia',
        name: 'Encyclopedia',
        type: 'link',
        icon: 'language'
    }, {
        state: 'https://www.agrilio.com/course-list/',
        name: 'E-Learning',
        type: 'extTabLink',
        icon: 'school'
    }, {
        state: '',
        name: 'Explore',
        type: 'separator',
        icon: 'av_timer'
    }, {
        state: 'producer',
        name: 'Producers',
        type: 'link',
        icon: 'person'
    }, {
        state: 'cooperative',
        name: 'Cooperatives',
        type: 'link',
        icon: 'group'
    }, {
        state: 'manufacturer',
        name: 'Manufacturers',
        type: 'link',
        icon: 'business'
    }, {
        state: 'authority',
        name: 'Authorities',
        type: 'link',
        icon: 'assignment_ind'
    }, {
        state: 'vetandphyto',
        name: 'Vet. and Phyto.',
        type: 'link',
        icon: 'opacity'
    }, {
        state: '',
        name: 'Governance',
        type: 'separator',
        icon: 'av_timer'
    }, {
        state: 'transparency',
        name: 'Transparency',
        type: 'link',
        icon: 'account_balance'
    }, {
        state: 'dao',
        name: 'Votings',
        type: 'link',
        icon: 'how_to_vote'
    }, {
        state: 'charity',
        name: 'Charities',
        type: 'link',
        icon: 'favorite'
    }, {
        state: 'https://www.agrilio.com/faq/',
        name: 'Support & FAQ',
        type: 'extTabLink',
        icon: 'help'
    }
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
