// src/app/auth/auth-guard.service.ts
import { Injectable } from '@angular/core';
import { Router, CanActivateChild } from '@angular/router';
import {Observable} from 'rxjs/Observable';
import { Web3Service } from '../../../../../common/services';
import { AuthService } from '../auth/auth-service.service';
import { tap, take, map } from 'rxjs/operators';
declare let window: any;

@Injectable()
export class Web3GuardService implements CanActivateChild {
  constructor(public web3Service: Web3Service, public router: Router, public authService: AuthService) {}
 // async canActivateChild(): Promise<boolean> | Observable<boolean> {
  async canActivateChild(): Promise<boolean>  {
     let accounts: string[] = [];
    /*return this.authService.authState
                    .pipe(
                      map(authState => !!authState || this.web3Service.hasPersonalWeb3()),
                      take(1),
                      tap(authenticated => {
                        if (!authenticated) {
                            this.router.navigate(['auth/login']);
                        }
                      })
                    );*/


    // Test if Metamask or another provider is already injected
    if (this.web3Service.hasPersonalWeb3()) {
      return true;
    } else {
     // this.router.navigate(['authentication', 'login']);
      // return true;
    }

    try {
     await this.web3Service.injectMetamaskProvider();
     accounts = await this.web3Service.personalWeb3.eth.getAccounts();
    } catch (e) {
      console.log(e);
    }

    if (accounts[0]) {
      return true;
    }else {
      this.router.navigate(['authentication', 'lockscreen']);
      return false;
    }
  }
}
