import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { Web3GuardService } from './shared/services/web3/web3-guard.service';
import { AuthGuard } from './shared/services/auth/auth-guard.service';

export const AppRoutes: Routes = [
  { path: '', redirectTo: '/farm/', pathMatch: 'full' },
  {
    path: '',
    component: FullComponent,
   // canActivateChild: [Web3GuardService, AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
    /*  {
        path: '',
        redirectTo: 'dao/create',
        pathMatch: 'full'
      },
      {
        path: 'apps',
        loadChildren: './apps/apps.module#AppsModule'
      },
      {
        path: 'pages',
        loadChildren: './pages/pages.module#PagesModule'
      },
      {
        path: 'notary',
        loadChildren: './notary/notary.module#NotaryModule'
      },
      {
        path: 'dao',
        loadChildren: './dao/dao.module#DaoModule'
      },
      {
          path: 'transparency',
          loadChildren: './transparency/transparency.module#TransparencyModule'
      },
        {
            path: 'producer',
            loadChildren: './producer/producer.module#ProducerModule'
        },
        {
            path: 'cooperative',
            loadChildren: './cooperative/cooperative.module#CooperativeModule'
        },
        {
            path: 'manufacturer',
            loadChildren: './manufacturer/manufacturer.module#ManufacturerModule'
        },
        {
            path: 'wallet',
            loadChildren: './wallet/wallet.module#WalletModule'
        },
        {
            path: 'tracking',
            loadChildren: './tracking/tracking.module#TrackingModule'
        },
        {
            path: 'dashboard',
            loadChildren: './dashboard/dashboard.module#DashboardModule'
        },
        {
            path: 'crowdsale',
            loadChildren: './crowdsale/crowdsale.module#CrowdsaleModule'
        },
        {
          path: 'token',
          loadChildren: './token/token.module#TokenModule'
        },
        {
            path: 'exchange',
            loadChildren: './exchange/exchange.module#ExchangeModule'
        },
        {
            path: 'market',
            loadChildren: './market/market.module#MarketModule'
        },
        {
            path: 'auction',
            loadChildren: './auction/auction.module#AuctionModule'
        },
        {
            path: 'charity',
            loadChildren: './charity/charity.module#CharityModule'
        },
        {
            path: 'message',
            loadChildren: './message/message.module#MessageModule'
        },
        {
            path: 'patch',
            loadChildren: './patch/patch.module#PatchModule'
        },
        {
            path: 'encyclopedia',
            loadChildren: './encyclopedia/encyclopedia.module#EncyclopediaModule'
        },
        {
            path: 'animal',
            loadChildren: './animal/animal.module#AnimalModule'
        },*/
        {
            path: 'farm',
            loadChildren: './farm/farm.module#FarmModule'
        },
       /* {
            path: 'sensor',
            loadChildren: './sensor/sensor.module#SensorModule'
        },
        {
            path: 'explorer',
            loadChildren: './explorer/explorer.module#ExplorerModule'
        },
        {
            path: 'asset',
            loadChildren: './asset/asset.module#AssetModule'
        },
        {
            path: 'taskboard',
            loadChildren: './taskboard/taskboard.module#TaskboardModule'
        },
        {
            path: 'settings',
            loadChildren: './settings/settings.module#SettingsModule'
        },
        {
            path: 'authority',
            loadChildren: './authority/authority.module#AuthorityModule'
        },
        {
            path: 'vetandphyto',
            loadChildren: './vetandphyto/vetandphyto.module#VetandphytoModule'
        },
        {
            path: 'newsalerts',
            loadChildren: './newsalerts/newsalerts.module#NewsalertsModule'
        },
        {
            path: 'report',
            loadChildren: './report/report.module#ReportModule'
        },*/
        /*{
            path: 'produce',
            loadChildren: './produce/produce.module#ProduceModule'
        },
        {
            path: 'feedback',
            loadChildren: './feedback/feedback.module#FeedbackModule'
        }*/
    ]
  },
  {
    path: '',
    component: AppBlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren:
          './authentication/authentication.module#AuthenticationModule'
      }
    ]
  },
  {
      /* Where to go when path does not exist */
    path: '**',
    redirectTo: '/authentication/404'
  }
];
