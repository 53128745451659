import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth-service.service';
import { Observable } from 'rxjs';
import { tap, take, map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivateChild {

  constructor(private _auth: AuthService, private _router: Router) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this._auth.authState
                    .pipe(
                      map(authState => !!authState),
                      take(1),
                      tap(authenticated => {
                        if (!authenticated) {
                            this._router.navigate(['/authentication/login']);
                        }
                      })
                    )
                    ;

    /*if (this._auth.isLoggedIn()) {
      return true;
    }

    this._router.navigate(['auth/login'], { queryParams: { returnUrl: state.url }});
    return false;*/
  }

}
