import { Injectable } from '@angular/core';

import Web3 from 'web3';
import { environment } from '../../environments/environment';
/**
 * Based in Web3
 * References:
 * https://github.com/Neufund/commit.neufund.org/blob/master/app/web3/web3Service.ts
 *
 */
@Injectable()
export class Web3Service {
    // Metamask or Ledger Provider
    public personalWeb3: Web3 | undefined;
    // Infura provider
    public readonly rawWeb3: Web3;
    public accounts: string[];
    constructor() {
        this.rawWeb3 = new Web3(
                new Web3.providers.HttpProvider('http://localhost:8545'));
    }
    public hasPersonalWeb3(): boolean {
        return this.personalWeb3 !== undefined;
    }
    /**
     *  get web3 returns first the personal if not havve the personal, it returns the raw provider
     */
    public getWeb3(): Web3 {
        if ( this.hasPersonalWeb3()) {
            return this.personalWeb3;
        } else {
            return this.rawWeb3;
        }
    }


    public setRawProvider(provider: string): void {
        this.rawWeb3.setProvider(new Web3.providers.HttpProvider(`${provider}`));
    }
   /**
    * Metamask provider used in the browser
    */
   public async injectMetamaskProvider(): Promise<boolean> {
      // Modern dapp browsers...
      if ((<any>window).ethereum) {
        const ethereum = (<any>window).ethereum;
        (<any>window).web3 = new Web3(ethereum);
        try {
            // Request account access if needed
            await ethereum.enable();
            this.personalWeb3 = new Web3((<any>window).web3.currentProvider);
            this.accounts = await this._getAccounts();
            return true;
        } catch (error) {
            return false;
            // User denied account access...
        }
         // Legacy dapp browsers...
    } else if ((<any>window).web3) {
          const web3 = (<any>window).web3;
          (<any>window).web3 = new Web3(web3.currentProvider);
          this.personalWeb3 = new Web3((<any>window).web3.currentProvider);
          this.accounts = await this._getAccounts();
          return true;
      // Non-dapp browsers...
      } else {
          console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
          return false;
      }
   }

   /**
    * https://github.com/Neufund/ledger-wallet-provider
    */
   public async injectLedgerProvider() {


   }
   /**
    * Get accounts associated to provider
    */
   private _getAccounts(): Promise<string[]> {
    return this.personalWeb3.eth.getAccounts();
  }
  // @See https://medium.com/metamask/scaling-web3-with-signtypeddata-91d6efc8b290
 /* public signPersonlMsg(msgParams: {type: string, name: string, value: string}, from, callback) {

   return new Promise(() => this.personalWeb3.currentProvider.send({
                        jsonrpc 
                        method: 'eth_signTypedData',
                        params: [msgParams, from]
                    }, callback)

  }*/

}
